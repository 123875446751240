import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import logo from "../img/logo-middle-red.svg";

import linkedInSvg from "../img/Linkedin.svg";
import mediumSvg from "../img/Medium.svg";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Links {
      markdownRemark(frontmatter: { type: { eq: "links" } }) {
        frontmatter {
          linkedin
          medium
        }
      }
    }
  `);
  const {
    markdownRemark: {
      frontmatter: { linkedin, medium },
    },
  } = data;

  return (
    <footer className="custom-footer footer">
      <div className="content has-text-centered">
        <div className="container link-wrapper">
          <Link to="/" title="Logo">
            <img className="logo" src={logo} alt="Winnie Chung" />
          </Link>
          <div className="links columns">
            <a className="linkedin" href={linkedin}>
              <img src={linkedInSvg} alt="LinkedIn" />
            </a>
            <a className="medium" href={medium}>
              <img src={mediumSvg} alt="Medium" />
            </a>
          </div>
        </div>
        <div className="container">
          <ul>
            <li>
              <Link to="/" className="navbar-item">
                Home
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/speakings">
                Speaking
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/blogs">
                Writings
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/resources">
                Resources
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
