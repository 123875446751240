import React, { useEffect, useState } from "react";
import arrow from "../img/scroll-to-top-arrow.svg";

const ScrollToTopButton = () => {
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const type = "scroll";
    const handler = (e) => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener(type, handler);
    return () => {
      window.removeEventListener(type, handler);
    };
  }, []);

  const onClick = () => {
    if ("scrollBehavior" in document.documentElement.style) {
      window.scrollTo({ behavior: "smooth", top: 0 });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <button
      className="scroll-to-top-btn"
      onClick={onClick}
      data-visible={scrollTop > 90}
    >
      <img src={arrow} alt="scroll to top"/>
    </button>
  );
};

export default ScrollToTopButton;
