import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo-middle-red.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.setState({
          navBarActiveClass: this.state.active ? "is-active" : "",
        });
      }
    );
  };

  render() {
    return (
      <nav
        className="custom-navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="nav-container container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img className="logo" src={logo} alt="Winnie Chung" />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/speakings">
                Speaking
              </Link>
              <Link className="navbar-item" to="/blogs">
                Writings
              </Link>
              <Link className="navbar-item" to="/resources">
                Resource
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
